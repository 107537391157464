import React, { useState, useEffect } from 'react'
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Calendar, LocaleConfig } from 'react-native-calendars'
import { Ionicons } from '@expo/vector-icons'
import { useQuery } from '@apollo/client'

import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { FIND_MANY_SCHEDULE, FIND_MANY_FOR_RESERVE_REQUEST } from '../gqls'

import Loading from '../components/Loading'
import Window from '../components/Window'

dayjs.extend(customParseFormat)

LocaleConfig.locales['ru'] = {
    monthNames: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
    ],
    monthNamesShort: [
        'Янв',
        'Февр',
        'Март',
        'Апр',
        'Май',
        'Июнь',
        'Июль',
        'Авг',
        'Сент',
        'Окт',
        'Нояб',
        'Дек'
    ],
    dayNames: [
        'Воскресенье',
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота'
    ],
    dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    today: 'Сегодня'
}

LocaleConfig.defaultLocale = 'ru'

const ReserveScreen = ({ route }) => {
    const { id, date } = route.params
    const navigation = useNavigation()
    const now = dayjs()

    const [currentDate, setCurrentDate] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [reverseSelectedDate, SetReverseSelectedDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)
    const [month, setMonth] = useState(parseInt(now.format('MM')))
    const [year, setYear] = useState(now.format('YYYY'))

    const [monthDates, setMonthDates] = useState([])

    const { data: findManyScheduleData, loading: findManyScheduleLoading } = useQuery(FIND_MANY_SCHEDULE, {
        fetchPolicy: 'network-only',
        variables: {
            where: {
                objectId: {
                    equals: id
                },
                status: {
                    equals: true
                }
            }
        }
    })

    const { data: findManyRequestData, loading: findManyRequestLoading } = useQuery(FIND_MANY_FOR_RESERVE_REQUEST, {
        fetchPolicy: 'network-only',
        variables: {
            where: {
                objectId: {
                    equals: id
                },
                status: {
                    notIn: ['3', '4']
                },
            }
        }
    })

    useEffect(() => {
        if (date) {
            const reserveDay = dayjs(date, 'DD.MM.YYYY').format('YYYY-MM-DD')
            setSelectedDate(reserveDay)
            setCurrentDate(reserveDay)
        } else {
            setCurrentDate(now.format('YYYY-MM-DD'))
        }
    }, [date])

    useEffect(() => {
        const dates = new Array(dayjs(`${year}-${month}-01`, { format: 'YYYY-MM-DD' }).daysInMonth()).fill(null).map((x, i) => dayjs(`${year}-${month}-01`, { format: 'YYYY-MM-DD' }).startOf('month').add(i, 'days').format('YYYY-MM-DD'))
        setMonthDates(dates)
    }, [month, year])

    if (!currentDate || findManyScheduleLoading || findManyRequestLoading) return <Loading />

    const { findManySchedule } = findManyScheduleData
    const { findManyRequest } = findManyRequestData

    const disableSchedule = monthDates.filter(object => now.format('YYYY-MM-DD') === object || now.isBefore(object)).reduce((acc, current) => {
        //TODO убрать после 15 января 2025
        if (dayjs(current) >= dayjs('2024-12-13') && dayjs(current) <= dayjs('2025-01-10')) {
            acc[current] = {
                disabled: true,
                disableTouchEvent: true
            }
            const days = findManySchedule.filter(schedule => schedule.days.some(object => object === dayjs(current).format('DD.MM.YYYY')) && schedule.status == true)
            if (days.length !== 0) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: false,
                    marked: true,
                    dotColor: '#66ff00'
                }
            }
            const requests = findManyRequest.filter(request => request.reservedAt === current)
            if ((requests.length > 0 && days.length > 0 && requests.length === days.length) || (requests.length > 0 && days.length === 0)) {
                acc[current] = {
                    disabled: true,
                    disableTouchEvent: true
                }
            }
            if ((requests.length > 0)) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: false,
                    marked: true,
                    dotColor: 'yellow'
                }
            }
            if (requests.length > 0 && requests.some(request => { return request.schedule != null && request.schedule.fullday })) {
                acc[current] = {
                    disabled: true,
                    disableTouchEvent: true
                }
            }
            const closes = findManySchedule.filter(schedule => schedule.closes.some(object => object === dayjs(current).format('DD.MM.YYYY')) && schedule.status == true)
            if ((closes.length > 0 && days.length > 0 && closes.length >= days.length) || (closes.length > 0 && days.length === 0 )) {
                acc[current] = {
                    disabled: true,
                    disableTouchEvent: true
                }
            }
            if ((closes.length > 0 && days.length > 0 && days.length >= closes.length) || (closes.length > 0  && days.length === 0 )) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: false,
                    marked: true,
                    dotColor: 'yellow'
                }
            }
            if ((requests.length > 0 && days.length > 0 && closes.length > 0 && (requests.length + closes.length) === days.length) || (requests.length > 0 && closes.length > 0 && days.length === 0)) {
                acc[current] = {
                    disabled: true,
                    disableTouchEvent: true
                }
            }
            if (closes.some(schedule => schedule.fullday)) {
                acc[current] = {
                    disabled: true,
                    disableTouchEvent: true
                };
            }
            const schedules = findManySchedule.filter(schedule => (schedule.days.some(object => object === dayjs(current).format('DD.MM.YYYY'))) && schedule.status == true)
                .filter(schedule => !requests.some(request => schedule.fullday || request.scheduleId === schedule.id && !schedule.fullday))
                .filter(schedule => !schedule.closes.some(object => object === dayjs(current).format('DD.MM.YYYY')))
                .filter(schedule => !closes.some(fullday => schedule.fullday))
            if (schedules.length == 0) {
                acc[current] = {
                    disabled: true,
                    disableTouchEvent: true
                };
            }
            return acc
        } else {
            acc[current] = {
                disabled: true,
                disableTouchEvent: true
            }
            const week = findManySchedule.filter(schedule => schedule.week.some(object => object.toLowerCase() === dayjs(current).format('dddd')) && schedule.status == true)
            if (week.length !== 0) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: false,
                    marked: true,
                    dotColor: '#66ff00'
                }
            }
            const days = findManySchedule.filter(schedule => schedule.days.some(object => object === dayjs(current).format('DD.MM.YYYY')) && schedule.status == true)
            if (days.length !== 0) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: false,
                    marked: true,
                    dotColor: '#66ff00'
                }
            }
            const requests = findManyRequest.filter(request => request.reservedAt === current)
            if ((requests.length > 0 && days.length > 0 && requests.length === days.length) || (requests.length > 0 && week.length > 0 && days.length === 0 && requests.length === week.length)) {
                acc[current] = {
                    disabled: true,
                    disableTouchEvent: true
                }
            }
            if ((requests.length > 0 && week.length > 0 && requests.length !== week.length)) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: false,
                    marked: true,
                    dotColor: 'yellow'
                }
            }
            if (requests.length > 0 && requests.some(request => { return request.schedule != null && request.schedule.fullday })) {
                acc[current] = {
                    disabled: true,
                    disableTouchEvent: true
                }
            }
            const closes = findManySchedule.filter(schedule => schedule.closes.some(object => object === dayjs(current).format('DD.MM.YYYY')) && schedule.status == true)
            if ((closes.length > 0 && days.length > 0 && closes.length >= days.length) || (closes.length > 0 && week.length > 0 && days.length === 0 && closes.length >= week.length)) {
                acc[current] = {
                    disabled: true,
                    disableTouchEvent: true
                }
            }
            if ((closes.length > 0 && days.length > 0 && days.length >= closes.length) || (closes.length > 0 && week.length > 0 && days.length === 0 && week.length > closes.length)) {
                acc[current] = {
                    disabled: false,
                    disableTouchEvent: false,
                    marked: true,
                    dotColor: 'yellow'
                }
            }
            if ((requests.length > 0 && days.length > 0 && closes.length > 0 && (requests.length + closes.length) === days.length) || (requests.length > 0 && week.length > 0 && closes.length > 0 && days.length === 0 && (requests.length + closes.length) === week.length)) {
                acc[current] = {
                    disabled: true,
                    disableTouchEvent: true
                }
            }
            if (closes.some(schedule => schedule.fullday)) {
                acc[current] = {
                    disabled: true,
                    disableTouchEvent: true
                };
            }
            const schedules = findManySchedule.filter(schedule => (schedule.days.some(object => object === dayjs(current).format('DD.MM.YYYY')) || schedule.week.some(object => object.toLowerCase() === dayjs(current).format('dddd'))) && schedule.status == true).filter((object) => (object.status == true))
                .filter(schedule => !requests.some(request => schedule.fullday || request.scheduleId === schedule.id && !schedule.fullday))
                .filter(schedule => !schedule.closes.some(object => object === dayjs(current).format('DD.MM.YYYY')))
                .filter(schedule => !closes.some(fullday => schedule.fullday))

            if (schedules.length == 0) {
                acc[current] = {
                    disabled: true,
                    disableTouchEvent: true
                };
            }

            //      console.log(`Date: ${current}, Requests: ${requests.length}, Days: ${days.length}, Week: ${week.length}, Closes: ${closes.length} schedules: ${schedules.length}`);
            const weekFullday = week.filter(schedule => !requests.some(request => schedule.fullday || request.scheduleId === schedule.id)).filter(schedule => !schedule.closes.some(object => object === dayjs(current).format('DD.MM.YYYY')))
            const daysFullday = days.filter(schedule => !requests.some(request => schedule.fullday || request.scheduleId === schedule.id)).filter(schedule => !schedule.closes.some(object => object === dayjs(current).format('DD.MM.YYYY')))

            // if (week.length > 0 && weekFullday.length === 0 || (days.length > 0 && daysFullday.length === 0)) {
            //     acc[current] = {
            //         disabled: true,
            //         disableTouchEvent: true

            //     }
            // }
            return acc
        }
    }, {})

    const closes = findManySchedule.filter(schedule => schedule.closes.some(object => object === dayjs(selectedDate).format('DD.MM.YYYY')) && schedule.status == true)
    const requests = findManyRequest.filter(request => request.reservedAt === selectedDate)
    let schedules = null;

    const openDays = findManySchedule.filter(schedule =>
        schedule.days.some(d => d === dayjs(selectedDate).format('DD.MM.YYYY')) && schedule.status == true
    );
    if (openDays.length > 0)
        schedules = findManySchedule.filter(schedule => (schedule.days.some(object => object === dayjs(selectedDate).format('DD.MM.YYYY'))) && schedule.status == true)
    else {
        if (dayjs(selectedDate) >= dayjs('2024-12-13') && dayjs(selectedDate) <= dayjs('2025-01-10')) {
            schedules = []
        } else {
            schedules = findManySchedule.filter(schedule => (schedule.days.some(object => object === dayjs(selectedDate).format('DD.MM.YYYY')) || schedule.week.some(object => object.toLowerCase() === dayjs(selectedDate).format('dddd'))) && schedule.status == true)
        }
    }
    const currentSchedules = schedules//.filter((schedule) => (schedule.days.some(day => day === dayjs(selectedDate).format('DD.MM.YYYY'))))
        .filter(schedule => !requests.some(request => schedule.fullday || request.scheduleId === schedule.id && !schedule.fullday))
        .filter(schedule => !schedule.closes.some(close => close === dayjs(selectedDate).format('DD.MM.YYYY')))
        .filter(schedule => !closes.some(fullday => schedule.fullday))

    return (
        <>
            <ScrollView showsVerticalScrollIndicator={false} style={styles.container}>
                <Window style={{ paddingHorizontal: 16 }}>
                    <View style={styles.calendarContainer}>
                        <Calendar
                            disabledByDefault
                            disableAllTouchEventsForDisabledDays
                            firstDay={1}
                            current={currentDate}
                            theme={{
                                selectedDayBackgroundColor: '#BCC8B2',
                                todayTextColor: '#000',
                            }}
                            renderArrow={(direction) => <Ionicons size={20} name={direction === 'left' ? 'ios-chevron-back' : 'ios-chevron-forward'} />}
                            onDayPress={day => {
                                if (now.isBefore(day.dateString) || now.isSame(day.dateString, 'day')) {
                                    SetReverseSelectedDate(day.dateString.split("-").reverse().join("."))
                                    setSelectedDate(day.dateString)
                                    setCurrentDate(day.dateString)
                                    setSelectedTime(null)
                                }
                            }}
                            markedDates={{
                                ...disableSchedule,
                                [selectedDate]: { selected: true, disableTouchEvent: true, selectedDotColor: 'orange' }
                            }}
                            onMonthChange={(date) => {
                                setMonth(date.month)
                                setYear(date.year)
                            }}
                        />
                    </View>
                    {
                        currentSchedules.map((object, index) => (
                            <View key={index} style={styles.timeContainer}>
                                <Text style={styles.title}>{object.name}</Text>
                                <TouchableOpacity onPress={() => setSelectedTime(object.id)} style={[styles.time, object.id === selectedTime ? { backgroundColor: '#BCC8B2', borderWidth: 0 } : null]}>
                                    <Text style={[styles.timeText, object.id === selectedTime ? { color: '#fff' } : null]}>{object.startAt} - {object.endAt}</Text>
                                    {
                                        object.discount && object.days.includes(reverseSelectedDate) && object.price > object.discount ? (
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text style={[styles.timeText, { textDecorationLine: 'line-through', color: '#667085' }]}>{object.price} ₽</Text>
                                                <Text style={[styles.timeText, object.id === selectedTime ? { color: '#fff' } : null]}> {object.discount} ₽</Text>
                                            </View>
                                        ) :
                                            (
                                                <Text style={[styles.timeText, object.id === selectedTime ? { color: '#fff' } : null]}>{object.discount && object.days.includes(reverseSelectedDate) || object.price} ₽</Text>
                                            )}
                                </TouchableOpacity>
                            </View>)
                        )
                    }
                </Window>
            </ScrollView>
            {
                selectedDate && selectedTime ? (
                    <Window>
                        <View style={styles.footer}>
                            <TouchableOpacity
                                onPress={() => navigation.navigate('Confirm', { scheduleId: selectedTime, objectId: id, selectedDate })}
                                style={styles.button}
                            >
                                <Text style={styles.buttonText}>Оформить бронь</Text>
                            </TouchableOpacity>
                        </View>
                    </Window>
                ) : null
            }
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    title: {
        color: '#101828',
        fontWeight: '500',
        fontSize: 16,
        marginBottom: 16
    },
    calendarContainer: {
        marginVertical: 32
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        borderRadius: 8,
        backgroundColor: '#E6B762',
    },
    buttonText: {
        color: '#101828',
        fontWeight: '500'
    },
    timeContainer: {
        alignItems: 'flex-start',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#D0D5DD',
        paddingVertical: 16,
        marginBottom: 24,
        padding: 16
    },
    time: {
        height: 60,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#D0D5DD',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 16
    },
    timeText: {
        fontSize: 16,
    },
    footer: {
        borderTopWidth: 1,
        borderColor: '#D0D5DD',
        padding: 16
    },
})

export default ReserveScreen